import React from "react"
import { ModalProvider } from "./src/context/modal"

export const RootLayout = ({ children }) => {
  return (
    <>
      <ModalProvider>{children}</ModalProvider>
    </>
  )
}
