import React, { createContext, useContext, useMemo, useState } from "react"

// State management pattern used - read more about it here.
// https://kentcdodds.com/blog/application-state-management-with-react

const ModalContext = createContext()

export const useModal = () => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error(`useModal must be used within a ModalProvider`)
  }
  return context
}

export const ModalProvider = props => {
  const [modal, setModal] = useState(false)
  const value = useMemo(() => [modal, setModal], [modal])
  return <ModalContext.Provider value={value} {...props} />
}
